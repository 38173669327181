import leoh from './leoh.webp';
import casperc from './casperc.webp';
import yakshithk from './yakshithk.webp';
import andrewd from './andrewd.webp';
import yimingc from './yimingc.webp';
import parsaa from './parsaa.webp';

const team = {
    leoh,
    casperc,
    yakshithk,
    andrewd,
    yimingc,
    parsaa
}

export default team;